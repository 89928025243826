export type ProjectStatus = 'Mới' | 'Đang thực hiện' | 'Hoàn thành' | 'Trạng thái';
export interface Project {
  id: number;
  name: string;
  location: string;
  startDate: string;
  status?: ProjectStatus;
}

export const ProjectList: Project[] = [
  { id: 1, name: 'Mỏ Đá', location: 'Hoà Bình', startDate: '01/01/2024', status: 'Mới' },
  { id: 2, name: 'Mỏ Đất', location: 'Hoà Bình', startDate: '01/01/2024', status: 'Mới' },
];